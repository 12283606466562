import Api        from "../../../services/api";
import * as types from "../../types";
import {Promise}  from "q";

/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 03/01/2021
 * @update 03/01/2021
 */

export function loadForm(param, cb) {
    return () => {
        Api.put('/w17f2040/load-form', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function saveData(param, cb) {
    return () => {
        Api.post('/w17f2040/save', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// Load Voucher
export function loadVoucher(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2040/voucher-num', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// Check Edit
export function checkEdit(param, cb) {
    return (dispatch) => {
        Api.put('/w19f2000/check-edit', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// Check update inventory
export function checkUpdateInventoryID(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2040/check-update-inventory', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// Load Currency
export function loadPriceList(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2000/load-currency', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// Reload Grid after chosen W17F2030
export function reLoadGrid(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2000/load-grid', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// Check Status
export function checkStatus(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2040/check-update-status', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// Approval
export function approval(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2040/approve', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// Cancel Approval
export function cancelApproval(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2040/un-approve', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

//========CÁC COMBO==========
// Get DMF Setting
export function getDmfSetting(cb) {
    return (dispatch) => {
        Api.get('/w17f2000/get-dmf-setting')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getDmfSettingSuccess(result.data));
                }
            });
    };
}

function getDmfSettingSuccess(data) {
    return {
        type: types.W17_W17F2040_GET_DMF_SETTING_SUCCESS,
        dataDMFSetting: data
    };
}

// Combo Division
export function getCboDivision(cb) {
    return (dispatch) => {
        Api.get('/w17f2000/load-combo-division')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboDivisionSuccess(result.data));
                }
            });
    };
}

function getCboDivisionSuccess(data) {
    return {
        type: types.W17_W17F2040_GET_CBO_DIVISION_SUCCESS,
        dataCboDivision: data
    };
}

// Combo TranType
export function getCboTranType(cb) {
    return (dispatch) => {
        Api.get('/w17f2040/load-cbo-trantype')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboTranTypeSuccess(result.data));
                }
            });
    };
}

function getCboTranTypeSuccess(data) {
    return {
        type: types.W17_W17F2040_GET_CBO_TRANTYPE_SUCCESS,
        dataCboTranType: data
    };
}

// Combo Employee
export function getCboEmployee(params, cb) {
    return (dispatch) => {
        Api.put('/w17f2000/load-combo-employee', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboEmployeeSuccess(result.data));
                }
            });
    };
}

function getCboEmployeeSuccess(data) {
    return {
        type: types.W17_W17F2040_GET_CBO_EMPLOYEE_SUCCESS,
        dataCboEmployee: data
    };
}

// Combo Delivery
export function getCboDelivery(cb) {
    return (dispatch) => {
        Api.get('/w17f2000/load-combo-delivery')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboDeliverySuccess(result.data));
                }
            });
    };
}

function getCboDeliverySuccess(data) {
    return {
        type: types.W17_W17F2040_GET_CBO_DELIVERY_SUCCESS,
        dataCboDeliveryMethod: data
    };
}

// Combo Payment Term
export function getCboPaymentTerm(cb) {
    return (dispatch) => {
        Api.get('/w17f2000/load-combo-payment-term')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboPaymentTermSuccess(result.data));
                }
            });
    };
}

function getCboPaymentTermSuccess(data) {
    return {
        type: types.W17_W17F2040_GET_CBO_PAYMENT_TERM_SUCCESS,
        dataCboPaymentTerm: data
    };
}

// Combo Payment Term
export function getCboPaymentMethod(cb) {
    return (dispatch) => {
        Api.get('/w17f2000/load-combo-payment-method')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboPaymentMethodSuccess(result.data));
                }
            });
    };
}

function getCboPaymentMethodSuccess(data) {
    return {
        type: types.W17_W17F2040_GET_CBO_PAYMENT_METHOD_SUCCESS,
        dataCboPaymentMethod: data
    };
}

// Combo Currency
export function getCboCurrency(cb) {
    return (dispatch) => {
        Api.get('/w17f2000/load-combo-currency')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboCurrencySuccess(result.data));
                }
            });
    };
}

function getCboCurrencySuccess(data) {
    return {
        type: types.W17_W17F2040_GET_CBO_CURRENCY_SUCCESS,
        dataCboCurrency: data
    };
}

// Combo Status Master
export function getCboStatus(cb) {
    return (dispatch) => {
        Api.get('/w17f2040/load-cbo-status')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboStatusSuccess(result.data));
                }
            });
    };
}

// Combo Status W17F2020
export function getCboStatusW17F2020(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2020/load-cbo-status', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

function getCboStatusSuccess(data) {
    return {
        type: types.W17_W17F2040_GET_CBO_STATUS_SUCCESS,
        dataCboStatus: data
    };
}

// Combo VATGroup
export function getCboVATGroup(cb) {
    return (dispatch) => {
        Api.get('/w17f2000/load-combo-VATgroup')
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboVATGroupSuccess(result.data));
                }
            });
    };
}

function getCboVATGroupSuccess(data) {
    return {
        type: types.W17_W17F2040_GET_CBO_VAT_GROUP_SUCCESS,
        dataCboVATGroup: data
    };
}

//Load combo VAT
export function getCboVAT(params, cb) {
    return (dispatch) => {
        Api.put('/w17f2040/load-object-VAT', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(getCboVATSuccess(result.data));
                }
            });
    };
}

function getCboVATSuccess(data) {
    return {
        type: types.W17_W17F2040_GET_CBO_VAT_SUCCESS,
        dataCboVAT: data
    };
}

//Load combo ware house
export function loadCboWareHouse(params, cb) {
    return (dispatch) => {
        Api.put('/w17f5560/get-cbo-warehouse', params)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                    dispatch(loadCboWareHouseComplete(result.data));
                }
            });
    };
}

function loadCboWareHouseComplete(data) {
    return {
        type: types.W17_W17F2040_GET_CBO_WAREHOUSE_SUCCESS,
        data: data
    }
}

// Combo Contact Person
export function getCboContactPerson(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2000/load-combo-contact-person', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// calculate acol
export function calcQuantity(params) {
    return async () => {
        return await new Promise((resolve => {
            Api.put('/w17f2040/get-quantity', params)
                .then(result => {
                    if (result && result.code && result.code !== 200) {
                        return resolve(null);
                    } else {
                        return resolve(result.data);
                    }
                });
        }));
    }
}

// Check Save
export function checkSaveData(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2040/check', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

// check task
export function checkTask(param, cb) {
    return (dispatch) => {
        Api.put('/w17f2040/check-task', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}

export function loadUnitPrice(param, cb) {
    return () => {
        Api.put('/w17f2000/unit-price', param)
            .then(result => {
                if (result && result.code && result.code !== 200) {
                    if (cb) cb(result, null);
                } else {
                    if (cb) cb(null, result.data);
                }
            });
    };
}